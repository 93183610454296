import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';

import LinkColumnItem from '../Footer/LinkColumn/LinkColumnItem';
import FooterBottomRow from '../Footer/FooterBottomRow';
import { mobileLinkDrawerConfig, filterLinks } from '../footerHelpers';
import { MobileFooterAppStoreRow } from './MobileFooterAppStoreRow';
import { MobileFooterDrawer } from './MobileFooterDrawer';

import styles from './MobileFooter.scss';

import {
    type MobileFooter_viewer$data,
    type MobileFooter_viewer$key,
} from './__generated__/MobileFooter_viewer.graphql';

export type mobileEnablerKeys = keyof MobileFooter_viewer$data;

type Props = {
    viewer: MobileFooter_viewer$key;
};

export const MobileFooter: FC<Props> = ({ viewer: viewerRef }) => {
    const viewer = useFragment(
        graphql`
            fragment MobileFooter_viewer on Viewer {
                footerNftLink: isEnabledByLocale(featureName: "NFT_LINK")
                footerQuestionsAnswersLink: isEnabledByLocale(featureName: "QUESTIONS_LINK")
                sitemapLinks: isEnabledByLocale(featureName: "SITEMAP")
                wpEditorialLink: isEnabledByLocale(featureName: "WP_EDITORIAL_LINK")
                ...FooterSubscribe_viewer
                ...FooterBottomRow_viewer
            }
        `,
        viewerRef
    );

    return (
        <div>
            {mobileLinkDrawerConfig.map((linkConfig, index) => {
                const { enablerKey, disablerKey, items, title, isExpanded, isAlwaysExpanded } =
                    linkConfig;
                let showLinkColumn = true;

                if (enablerKey) {
                    showLinkColumn = viewer[enablerKey as mobileEnablerKeys] === true;
                }
                if (disablerKey) {
                    showLinkColumn = viewer[disablerKey as mobileEnablerKeys] === false;
                }
                const isColumnExpanded = isExpanded || isAlwaysExpanded;
                const content = (
                    <ul className={styles.list}>
                        {filterLinks(items, viewer).map(item => {
                            const CustomComponent = item.CustomComponent;
                            return CustomComponent ? (
                                <CustomComponent key={item.dataTn} {...item} viewer={viewer} />
                            ) : (
                                <LinkColumnItem key={item.dataTn} {...item} />
                            );
                        })}
                    </ul>
                );
                return (
                    showLinkColumn && (
                        <MobileFooterDrawer
                            key={index}
                            title={title}
                            expandable={!isAlwaysExpanded}
                            isExpanded={isColumnExpanded}
                            content={content}
                        />
                    )
                );
            })}
            <MobileFooterAppStoreRow />
            <FooterBottomRow viewer={viewer} filterLinks={links => filterLinks(links, viewer)} />
        </div>
    );
};
